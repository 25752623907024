let tagMsg = {
    "2259814": {
        "area": "综管",
        "name": "行政柜"
    },
    "2259805": {
        "area": "综管",
        "name": "空调"
    },
    "2255341": {
        "area": "综管",
        "name": "捷哥柜子"
    },
    "2255343": {
        "area": "综管",
        "name": "捷哥桌子"
    },
    "2259817": {
        "area": "综管",
        "name": "文进桌子"
    },
    "2255342": {
        "area": "会议室",
        "name": "电视"
    },
    "2259810": {
        "area": "会议室",
        "name": "桌子"
    },
    "2259821": {
        "area": "会议室",
        "name": "桌子"
    },
    "2259809": {
        "area": "前台",
        "name": "快递架"
    },
    "2259829": {
        "area": "仓库",
        "name": "货架1"
    },
    "2259827": {
        "area": "仓库",
        "name": "货架2"
    },
    "2259828": {
        "area": "楼道",
        "name": "楼道正面"
    },
    "2259800": {
        "area": "楼道",
        "name": "门口"
    },
    "2259801": {
        "area": "软件",
        "name": "微波炉"
    },
    "2259808": {
        "area": "软件",
        "name": "消毒柜"
    },
    "2259819": {
        "area": "软件",
        "name": "冰箱"
    },
    "2255345": {
        "area": "软件",
        "name": "空调"
    },
    "2259804": {
        "area": "软件",
        "name": "文佳桌子"
    },
    "2255349": {
        "area": "软件",
        "name": "桌子第一排"
    },
    "2259812": {
        "area": "软件",
        "name": "桌子第三排"
    },
    "2259825": {
        "area": "总经办",
        "name": "投影架子"
    },
    "2259802": {
        "area": "总经办",
        "name": "空调"
    },
    "2255347": {
        "area": "总经办",
        "name": "架子"
    },
    "2259813": {
        "area": "孙总办公室",
        "name": "茶几"
    },
    "2255344": {
        "area": "孙总办公室",
        "name": "柜子"
    },
    "1903846": {
        "area": "104-2",
        "name": "测试标签1"
    },
    "1903880": {
        "area": "104-2",
        "name": "测试标签3"
    },
    "1903881": {
        "area": "104-1-1",
        "name": "测试标签4"
    },
    "1903886": {
        "area": "104-1-1",
        "name": "测试标签5"
    },
    "1903897": {
        "area": "104-1-2",
        "name": "测试标签6"
    },
    "1903889": {
        "area": "104-1-2",
        "name": "测试标签7"
    },
    "2156442": {
        "area": "硬件",
        "name": "勇枝电脑"
    },
    "2259824": {
        "area": "硬件",
        "name": "空调"
    },
    "2156441": {
        "area": "硬件",
        "name": "跃鑫电脑"
    },
    "2259806": {
        "area": "硬件",
        "name": "机柜"
    },
    "2259807": {
        "area": "硬件",
        "name": "饮水机"
    },
    "2156447": {
        "area": "硬件",
        "name": "钟声电脑"
    },
    "2255340": {
        "area": "波哥仓库",
        "name": "冰箱"
    },
    "2255346": {
        "area": "波哥仓库",
        "name": "货架"
    },
    "2259803": {
        "area": "生产",
        "name": "货架1"
    },
    "2259815": {
        "area": "生产",
        "name": "货架2"
    },
    "2156445": {
        "area": "生产",
        "name": "货架3"
    },
    "1903835": {
        "area": "生产",
        "name": "空调"
    },
    "2259811": {
        "area": "生产",
        "name": "电脑"
    },
    "2259822": {
        "area": "104-2",
        "name": "测试标签2"
    },
    "2259826": {
        "area": "软件",
        "name": "段磊2"
    },
    "2259818": {
        "area": "软件",
        "name": "段磊3"
    },
    "2259823": {
        "area": "软件",
        "name": "段磊4"
    },
}

export {
    tagMsg
}