<template>
  <div style="background-color: #ececec; padding: 20px">
    <div style="position: fixed; right: 20px">
      <a-switch v-model:checked="showAll"/>
      <p>查看所有</p>
    </div>


    <a-row :gutter="16" style="background-color: #ececec">
      <a-col v-for="(gateway) in gatewayMsg" v-bind:key="gateway.gatewayId" :span="4">
        <a-card v-bind:title=gateway.gatewayName :bordered="false">
          <a-popover v-for="(tag) in gateway.tagList.filter(tag=>tag.ifOwn || this.$data.showAll)"
                     v-bind:key="tag.cardId" title="详细信息">
            <template #content>
              <p>编号：{{ tag.cardId }} </p>
              <p v-if=tagMsg[tag.cardId]>名称：{{ tagMsg[tag.cardId]["name"] }} </p>
              <p>信号值：{{ tag.signal }}</p>
              <p>距离：{{ tag.distance }}m </p>
              <p>更新时间: {{ format(new Date(tag.updateTime), 'yyyy-MM-dd HH:mm:ss') }}</p>
              <p>信号趋势：
                <CaretUpOutlined style="color: green" v-if="tag.signalTrend > 0"/>
                <CaretDownOutlined style="color: red" v-else-if="tag.signalTrend < 0"/>
                <MinusOutlined style="color: blue" v-else/>
                {{ tag.signalTrend }}
              </p>
              <p v-if=tagMsg[tag.cardId]>位置：{{ tagMsg[tag.cardId]["area"] }} </p>
              <div v-html="getOtherGatewaySignal(gateway.gatewayName, tag.cardId)"></div>
            </template>
            <a-tag v-if=tagMsg[tag.cardId] :color="tag.ifOwn?'blue':'red'">
              {{ tag.cardId }} {{ tagMsg[tag.cardId]["area"] }} {{ tagMsg[tag.cardId]["name"] }}
            </a-tag>
            <a-tag v-else :color="tag.ifOwn?'blue':'red'">
              {{ tag.cardId }} 未知标签
            </a-tag>
          </a-popover>
        </a-card>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import {tagMsg} from "@/tag";
import {notification} from 'ant-design-vue';
import format from 'date-fns/format';
import {CaretUpOutlined, CaretDownOutlined, MinusOutlined} from '@ant-design/icons-vue';

console.log(tagMsg)

export default {
  name: 'HelloWorld',
  props: {
    msg: String
  },
  components: {
    CaretUpOutlined,
    MinusOutlined,
    CaretDownOutlined
  },
  data() {
    return {
      path: "ws://47.107.102.103:8081/ws",
      socket: null,
      tagMsg: tagMsg,
      gatewayMsg: null,
      showAll: false
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    init: function () {
      if (typeof (WebSocket) === "undefined") {
        alert("您的浏览器不支持socket")
      } else {
        this.socket = new WebSocket(this.path)
        this.socket.onopen = this.open
        this.socket.onerror = this.error
        this.socket.onmessage = this.onMessage
        this.socket.close = this.close
      }
    },
    format,
    open: function () {
      console.log("socket连接成功")
    },
    error: function () {
      console.log("连接错误")
    },
    getOtherGatewaySignal: function (gatewayName, cardId) {
      let msg = "其他网关信号：<br/><br/>";
      for (const gatewayMsgKey of this.$data.gatewayMsg) {
        if (gatewayMsgKey.gatewayName === gatewayName) {
          continue;
        } else {
          let tag = gatewayMsgKey.tagList.find(tag => tag.cardId === cardId);
          if (tag) {
            msg += "<div>" + gatewayMsgKey.gatewayName + ":" + tag.signal + "<br/>更新：" + format(new Date(tag.updateTime), 'yyyy-MM-dd HH:mm:ss') + "<br/>";
            if(tag.signalTrend > 0){
              msg += `趋势: <span role="img" aria-label="caret-up" class="anticon anticon-caret-up" data-v-469af010="" style="color: green;"><svg focusable="false" class="" data-icon="caret-up" width="1em" height="1em" fill="currentColor" aria-hidden="true" viewBox="0 0 1024 1024"><path d="M858.9 689L530.5 308.2c-9.4-10.9-27.5-10.9-37 0L165.1 689c-12.2 14.2-1.2 35 18.5 35h656.8c19.7 0 30.7-20.8 18.5-35z"></path></svg></span> ${tag.signalTrend}<br/>`;
            } else if(tag.signalTrend === 0){
              msg += `趋势: <span role="img" aria-label="minus" class="anticon anticon-minus" data-v-469af010="" style="color: blue;"><svg focusable="false" class="" data-icon="minus" width="1em" height="1em" fill="currentColor" aria-hidden="true" viewBox="64 64 896 896"><path d="M872 474H152c-4.4 0-8 3.6-8 8v60c0 4.4 3.6 8 8 8h720c4.4 0 8-3.6 8-8v-60c0-4.4-3.6-8-8-8z"></path></svg></span> ${tag.signalTrend}<br/>`;
            } else {
              msg += `趋势: <span role="img" aria-label="caret-down" class="anticon anticon-caret-down" data-v-469af010="" style="color: red;"><svg focusable="false" class="" data-icon="caret-down" width="1em" height="1em" fill="currentColor" aria-hidden="true" viewBox="0 0 1024 1024"><path d="M840.4 300H183.6c-19.7 0-30.7 20.8-18.5 35l328.4 380.8c9.4 10.9 27.5 10.9 37 0L858.9 335c12.2-14.2 1.2-35-18.5-35z"></path></svg></span> ${tag.signalTrend}<br/>`;
            }
            msg  += "<br/></div>"
          }
        }
      }
      return msg;
    },
    onMessage: function (msg) {
      let data = JSON.parse(msg.data)
      if (data["msgType"] == "info") {
        let cardId = data["msgPayload"]["cardId"];
        let oldG = data["msgPayload"]["old"];
        let newG = data["msgPayload"]["new"];
        let desc = "";
        if (tagMsg[cardId]) {
          desc = tagMsg[cardId]["area"] + "的" + tagMsg[cardId]["name"] + "从" + oldG + "迁移到" + newG
        } else {
          desc = cardId + "从" + oldG + "迁移到" + newG
        }
        notification["info"]({
          message: cardId + '移动通知',
          description: desc,
        });
      } else if (data["msgType"] == "cardInfo") {
        this.$data.gatewayMsg = data["msgPayload"]
        for (const gatewayMsgKey of this.$data.gatewayMsg) {
          gatewayMsgKey.tagList.sort((tag1) => tag1.ifOwn ? -1 : 1);
        }
      }
      console.log(msg.data)
    },
    close: function () {
      console.log("socket已经关闭")
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}
</style>
